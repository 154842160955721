import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

import IAssureTable from "../IAssureTable/IAssureTable.jsx";
import Select from "react-select";

export default () => {
  const { orderProductId } = useParams(); // Get the product ID from the URL
  const [editingProduct, setEditingProduct] = useState("");
  const [catgArr, setCatgArr] = useState([]);
  const [subcatgArr, setSubCatgArr] = useState([]);
  const [selectedCatg, setSelectedCatg] = useState("");
  const [selectedSubCatg, setSelectedSubCatg] = useState("");
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [orderDate, setOrderDate] = useState("");
  const [unitPackSize, setUnitPackSize] = useState("");
  const [pricePerKg, setPricePerKg] = useState("");
  const [quantity, setQuantity] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [expectedDeliveryDate, setExpectedDeliveryDate] = useState("");
  const [trackingNumber, setTrackingNumber] = useState("");
  const [deliveryStatus, setDeliveryStatus] = useState("Dispatch"); // Default value
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [options, setOptions] = useState([]);
  const [userName, setUserName] = useState(null);

  const [id, setId] = useState("order_List");
  const [tableData, setTableData] = useState([]);
  const [startRange, setStartRange] = useState(0);
  const [limitRange, setLimitRange] = useState(10);
  const [apiLink, setApiLink] = useState("/api/order-product");
  const [twoLevelHeader, setTwoLevelHeader] = useState(false);
  const [deleteMethod, setDeleteMethod] = useState("delete");
  const [dataCount, setDataCount] = useState(0);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      // color: state.isSelected ? 'gray' : 'black',
    }),
    singleValue: (provided) => ({
      ...provided,
      // color: 'gray',
    }),
    control: (provided, state) => ({
      ...provided,
      width: 250, // Set the width
      borderRadius: 0, // Set the border radius
      borderColor: state.isFocused ? "gray" : provided.borderColor,
      boxShadow: state.isFocused ? "0 0 0 1px gray" : provided.boxShadow,
    }),
  };

  const tableHeading = {
    selectedProduct: "Product Name",
    businessCategory: "Category",
    businessSubCategory: "SubCategory",
    orderDate: "Order Date",
    unitPackSize: "Unit Pack Size",
    pricePerKg: "Price/Kg",
    quantity: "Quantity",
    totalAmount: "Total Amount",
    expectedDeliveryDate: "Expected Delivery Date",
    trackingNumber: "Tracking Number",
    deliveryStatus: "Delivery Status",
    userName: "User Name",
    actions: "Actions",
  };

  const tableObjects = {
    editUrl: "/order-status",
    apiLink: "/api/order-product",
    paginationApply: true,
    // searchApply: true,
    downloadApply: true,
    deleteMethod: "delete",
  };

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  // Fetch data when component mounts
  // useEffect(() => {
  //   getData();
  // }, []);

  useEffect(() => {
    //getData();
    //fetchData();
    axios
      .get("/api/businesscategory/get/list")
      .then((catgList) => {
        console.log("catgList => ", catgList.data);
        setCatgArr(catgList.data);
      })
      .catch((err) => {
        console.error("Error fetching categories:", err.message);
      });
    if (orderProductId) {
      console.log(orderProductId, "orderProductId");
      // Fetch product data using the provided product ID
      axios
        .get(`/api/order-product/get/${orderProductId}`)
        .then((response) => {
          console.log("response.data", response.data.businessCategory);
          setEditingProduct(response.data); // Set the editing product
          setSelectedProduct(response.data.selectedProduct);
          setSelectedCatg(response.data.businessCategory);
          setSelectedSubCatg(response.data.businessSubCategory);
          setOrderDate(response.data.orderDate);
          setUnitPackSize(response.data.unitPackSize);
          setPricePerKg(response.data.pricePerKg);
          setQuantity(response.data.quantity);
          setTotalAmount(response.data.totalAmount);
          setExpectedDeliveryDate(response.data.expectedDeliveryDate);
          setTrackingNumber(response.data.trackingNumber);
          setDeliveryStatus(response.data.deliveryStatus);
          setUserName(response.data.userName);
        })
        .catch((error) => {
          console.error("Error fetching product:", error.message);
        });
    }
  }, []);

  // useEffect(() => {
  //   axios
  //     .get("/api/businesscategory/get/list")
  //     .then((catgList) => {
  //       console.log("catgList => ", catgList.data);
  //       setCatgArr(catgList.data);
  //     })
  //     .catch((err) => {
  //       console.error("Error fetching categories:", err.message);
  //     });
  // }, []);

  const categoryChange = (event) => {
    const categoryId = event.target.value;
    setSelectedCatg(event.target.value);
    axios
      .get("/api/business-subcategory/get/list/" + categoryId)
      .then((subcatgList) => {
        console.log("subcatgList => ", subcatgList.data.subcategoryData);
        setSubCatgArr(subcatgList.data.subcategoryData);
      })
      .catch((err) => {
        console.error("Error fetching categories:", err.message);
      });
  };

  const subCategoryChange = (event) => {
    const categoryId = selectedCatg; // Use the previously selected categoryId
    const subcategoryId = event.target.value;
    console.log("categoryId => ", categoryId);
    console.log("subcategoryId => ", subcategoryId);
    setSelectedSubCatg(subcategoryId);
    axios
      .post(`/api/products/get/list/${categoryId}/${subcategoryId}`)
      .then((response) => {
        setProducts(response.data.data);
        console.log(response.data.data);
      })
      .catch((err) => {
        console.error("Error fetching categories:", err.message);
      });
  };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  const fetchData = async () => {
    try {
      const response = await axios.post(`/api/users/get/list`, {
        role: "user",
      });

      const users = response.data;
      console.log("response", response);
      const formattedOptions = users.map((user) => ({
        value: user._id,
        label: user.profile.fullName,
      }));

      setOptions(formattedOptions);
    } catch (error) {
      console.error("Error fetching data:", error);
      if (!error.response) {
        // network error
        this.errorStatus = "Error: Network Error";
      } else {
        this.errorStatus = error.response.data.message;
      }
    }
  };

  const productChange = (event) => {
    setSelectedProduct(event.target.value);
  };

  const resetForm = () => {
    setSelectedCatg("");
    setSelectedSubCatg("");
    setSelectedProduct("");
    setOrderDate("");
    setUnitPackSize("");
    setPricePerKg("");
    setQuantity("");
    setTotalAmount("");
    setExpectedDeliveryDate("");
    setTrackingNumber("");
    setDeliveryStatus("Dispatch");
    setUserName("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formValues = {
      businessCategory: selectedCatg,
      businessSubCategory: selectedSubCatg,
      selectedProduct,
      orderDate,
      unitPackSize,
      pricePerKg,
      quantity,
      totalAmount,
      expectedDeliveryDate,
      trackingNumber,
      deliveryStatus,
      userName: userName.value,
      // userName,
      // Include other form fields as needed
    };
    console.log(formValues, "formValues");
    console.log("userName", userName);
    let submitPromise;

    if (editingProduct) {
      submitPromise = axios.patch(
        `/api/order-product/update/` + orderProductId,
        formValues
      );
    } else {
      submitPromise = axios.post("/api/order-product/post", formValues);
    }

    submitPromise
      .then((response) => {
        console.log("Product updated/created:", response);
        setEditingProduct(response.data);
        resetForm();
        setShowPopup(true);
        setPopupMessage("Product operation successful!");
        window.location.href = "/order-status";

        return getData(); // Chain getData after the handleSubmit API call
      })
      .catch((error) => {
        console.error("Error performing product operation:", error.message);
      });
  };

  const getData = () => {
    axios
      .get(apiLink + "/get/list")
      .then((response) => {
        const tableData = response.data.map((a, i) => {
          // Format the orderDate to show in DD-MM-YYYY format
          const formattedOrderDate = new Date(a.orderDate).toLocaleDateString(
            "en-GB"
          );

          // Format the expectedDeliveryDate to show in DD-MM-YYYY format
          const formattedExpectedDeliveryDate = new Date(
            a.expectedDeliveryDate
          ).toLocaleDateString("en-GB");

          return {
            _id: a._id,
            selectedProduct: a.selectedProduct,
            businessCategory: a.businessCategory,
            businessSubCategory: a.businessSubCategory,
            orderDate: formattedOrderDate,
            unitPackSize: a.unitPackSize,
            pricePerKg: a.pricePerKg,
            quantity: a.quantity,
            totalAmount: a.totalAmount,
            expectedDeliveryDate: formattedExpectedDeliveryDate,
            trackingNumber: a.trackingNumber,
            deliveryStatus: a.deliveryStatus,
            userName: a.userName,
          };
        });
        setTableData(tableData);
      })
      .catch((error) => {});

    axios
      .get(apiLink + "/get/count")
      .then((response) => {
        setDataCount(response.data.count);
      })
      .catch((error) => {});
  };

  const handleOrderDateChange = (e) => {
    const selectedOrderDate = e.target.value;
    setOrderDate(selectedOrderDate);
    // If you want to set Expected Delivery Date equal to Order Date by default
    setExpectedDeliveryDate(selectedOrderDate);
  };

  // const getData = () => {
  //   axios
  //     .get(apiLink + "/get/list")
  //     .then((response) => {
  //       const tableData = response.data.map((a, i) => {
  //         return {
  //           _id: a._id,
  //           selectedProduct: a.selectedProduct,
  //           businessCategory: a.businessCategory,
  //           businessSubCategory: a.businessSubCategory,
  //           orderDate: a.orderDate,
  //           unitPackSize: a.unitPackSize,
  //           pricePerKg: a.pricePerKg,
  //           quantity: a.quantity,
  //           totalAmount: a.totalAmount,
  //           expectedDeliveryDate: a.expectedDeliveryDate,
  //           trackingNumber: a.trackingNumber,
  //           deliveryStatus: a.deliveryStatus,
  //           userName: a.userName,
  //         };
  //       });
  //       setTableData(tableData);
  //     })
  //     .catch((error) => {});

  //   axios
  //     .get(apiLink + "/get/count")
  //     .then((response) => {
  //       setDataCount(response.data.count);
  //     })
  //     .catch((error) => {});
  // };

  return (
    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 NOPadding">
      <section className="content">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 pageContent">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 NOPadding topMargin box-header with-border">
                <h4 className="col-lg-12 col-md-12 col-xs-12 col-sm-12 weighttitle NOPadding">
                  Order Details Form
                </h4>
              </div>
            </div>
          </div>
          <div>
            <form
              style={{
                marginLeft: "1%",
              }}
              required
              onSubmit={handleSubmit}
            >
              <div
                className="form-group"
                style={{ display: "flex", gap: "30px", marginTop: "20px" }}
              >
                <div className="form-group">
                  <label>Select Category</label>
                  <br />
                  {console.log("369", selectedCatg)}
                  <select
                    id="category"
                    className="addonDiv col-lg-12 col-md-12 col-sm-12 col-xs-12"
                    defaultValue={"-"}
                    value={selectedCatg}
                    onChange={categoryChange}
                    style={{ width: "250px", height: "35px" }}
                    required
                  >
                    <option value="-">Select Category</option>
                    {catgArr.length > 0
                      ? catgArr.map((catg, index) => {
                          return (
                            <option key={index} value={catg._id}>
                              {catg.businessCategory}
                            </option>
                          );
                        })
                      : null}
                  </select>
                </div>
                <div className="form-group">
                  <label>Select Subcategory</label>
                  <br />
                  <select
                    id="subcategory"
                    className="addonDiv col-lg-12 col-md-12 col-sm-12 col-xs-12"
                    defaultValue={"-"}
                    value={selectedSubCatg}
                    onChange={subCategoryChange}
                    style={{ width: "250px", height: "35px" }}
                    required
                  >
                    <option value="-">Select Subcategory</option>
                    {subcatgArr.length > 0
                      ? subcatgArr.map((subcatg, index) => {
                          return (
                            <option key={index} value={subcatg._id}>
                              {subcatg.businessSubCategory}
                            </option>
                          );
                        })
                      : null}
                  </select>
                </div>
                <div className="form-group">
                  <label>Select Product</label>
                  <br />
                  <select
                    id="subcategory"
                    className="addonDiv col-lg-12 col-md-12 col-sm-12 col-xs-12 form-control "
                    defaultValue={"-"}
                    value={selectedProduct}
                    onChange={productChange}
                    style={{ width: "250px", height: "35px" }}
                    required
                  >
                    <option value="-">Select Product</option>
                    {products.length > 0
                      ? products.map((subcatg, index) => {
                          return (
                            <option key={index} value={subcatg._id}>
                              {subcatg.name}
                            </option>
                          );
                        })
                      : null}
                  </select>
                </div>
              </div>

              <div
                className="form-group"
                style={{ display: "flex", gap: "30px", marginTop: "30px" }}
              >
                <div className="form-group ">
                  <label>Unit Pack Size</label>
                  <br />
                  <input
                    type="text"
                    className="form-control "
                    value={unitPackSize}
                    onChange={(e) => setUnitPackSize(e.target.value)}
                    required
                    style={{ width: "250px", height: "35px" }} // Adjust the width here
                  />
                </div>
                <div className="form-group">
                  <label>Price (Per Kg)</label>
                  <br />
                  <input
                    type="number"
                    className="form-control "
                    step="0.01"
                    value={pricePerKg}
                    onChange={(e) => setPricePerKg(e.target.value)}
                    required
                    style={{ width: "250px", height: "35px" }} // Adjust the width here
                  />
                </div>
                <div className="form-group">
                  <label>Quantity</label>
                  <br />
                  <input
                    type="number"
                    className="form-control "
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                    required
                    style={{ width: "250px", height: "35px" }} // Adjust the width here
                  />
                </div>
              </div>
              <div
                className="form-group"
                style={{ display: "flex", gap: "30px", marginTop: "30px" }}
              >
                <div className="form-group">
                  <label>Total Amount</label>
                  <br />
                  <input
                    type="number"
                    className="form-control "
                    step="0.01"
                    value={totalAmount}
                    onChange={(e) => setTotalAmount(e.target.value)}
                    style={{ width: "250px", height: "35px" }}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Tracking Number</label>
                  <br />
                  <input
                    type="text"
                    className="form-control "
                    value={trackingNumber}
                    onChange={(e) => setTrackingNumber(e.target.value)}
                    style={{ width: "250px", height: "35px" }}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Delivery Status</label>
                  <br />
                  <select
                    value={deliveryStatus}
                    className="form-control "
                    onChange={(e) => setDeliveryStatus(e.target.value)}
                    style={{ width: "250px", height: "35px" }}
                    required
                  >
                    <option value="Dispatch">Dispatch</option>
                    <option value="In transit">In transit</option>
                    <option value="Delivered">Delivered</option>
                  </select>
                </div>
              </div>

              <div
                className="form-group"
                style={{ display: "flex", gap: "30px", marginTop: "30px" }}
              >
                <div className="form-group">
                  <label>Order Date</label>
                  <br />
                  <input
                    type="date"
                    className="form-control "
                    value={orderDate}
                    // onChange={(e) => setOrderDate(e.target.value)}
                    onChange={handleOrderDateChange}
                    style={{ width: "250px", height: "35px" }}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Expected Delivery Date</label>
                  <br />
                  <input
                    type="date"
                    className="form-control "
                    value={expectedDeliveryDate}
                    // onChange={(e) => setExpectedDeliveryDate(e.target.value)}
                    onChange={(e) => setExpectedDeliveryDate(e.target.value)}
                    style={{ width: "250px", height: "35px" }}
                    min={orderDate} // Set the minimum date for Expected Delivery Date
                    required
                  />
                </div>
                <div className="form-group">
                  <label>User Name</label>
                  <br />
                  {/* <select
                    id="subcategory"
                    className="addonDiv col-lg-12 col-md-12 col-sm-12 col-xs-12"
                    defaultValue={"-"}
                    onChange={subCategoryChange}
                    style={{ width: "250px", height: "35px" }}
                    required
                  >
                    <option value="-">Select Subcategory</option>
                    {subcatgArr.length > 0
                      ? subcatgArr.map((subcatg, index) => {
                          return (
                            <option key={index} value={subcatg._id}>
                              {subcatg.businessSubCategory}
                            </option>
                          );
                        })
                      : null}
                  </select> */}
                  <Select
                    options={options}
                    value={userName}
                    onChange={setUserName}
                    styles={customStyles}
                  />
                </div>
              </div>

              <button
                type="submit"
                className="btn btn-primary  "
                style={{ width: "180px", height: "35px" }}
              >
                {editingProduct ? "Update" : "Submit"}
              </button>
            </form>
            {showPopup && (
              <div className="alert">
                <p>{popupMessage}</p>
              </div>
            )}

            <div>
              <IAssureTable
                tableHeading={tableHeading}
                twoLevelHeader={twoLevelHeader}
                dataCount={dataCount}
                tableData={tableData}
                getData={getData.bind(this)}
                tableObjects={tableObjects}
                deleteMethod={deleteMethod}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
