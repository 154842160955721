import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import ProductList from "../ProductList/ProductList.js";
import IAssureTable1 from "../IAssureTable/IAssureTable1.jsx";




export default () => {
  const { product_id } = useParams(); // Get the product ID from the URL
  const [editingProduct, setEditingProduct] = useState(null);
  const [catgArr, setCatgArr] = useState([]);
  const [subcatgArr, setSubCatgArr] = useState([]);
  const [selectedCatg, setSelectedCatg] = useState([]); // Initialize as null
  const [selectedSubCatg, setSelectedSubCatg] = useState([]);
  const [name, setName] = useState("");
  const [productId, setProductId] = useState("");
  const [image, setImage] = useState(null);
  const [description, setDescription] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");


  const [id, setId] = useState("product_List");
  const [tableName, setTableName] = useState("Product_List");
  const [tableDatas, setTableDatas] = useState([]);
  const [reportData, setReportData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [startRange, setStartRange] = useState(0);
  const [limitRange, setLimitRange] = useState(10);
  const [apiLink, setApiLink] = useState("/api/products");
  const [twoLevelHeader, setTwoLevelHeader] = useState(false);
  const [deleteMethod, setDeleteMethod] = useState("delete");
  const [dataCount, setDataCount] = useState(0); // Added for dataCount

  const [nameError, setNameError] = useState("");
  const [productIdError, setProductIdError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [imageError, setImageError] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [subCategoryError, setSubCategoryError] = useState("");


  const editId = {       
   
  };

  const tableHeading = {       
    name: "Product Name",
    productId: "Product Id",
    description: "Description",
    image:"Image",
    businessCategory: "Category",
    businessSubCategory: "SubCategory",
    actions: 'Actions',
  };
  
  const tableObjects = {
    editUrl: "/product-upload",
    apiLink: "/api/products",
    paginationApply: true,
    // searchApply: true,
    downloadApply: true,
    deleteMethod: "delete"
  };
  useEffect(() => {
    // Call getData on component mount to populate initial data
    getData();
  }, []);
  useEffect(() => {
    console.log('Editing Product Updated:', editingProduct);
  }, [editingProduct]);

  
  useEffect(() => {
    if (product_id) {
      console.log(product_id, "product_id");
      // Fetch product data using the provided product ID
      axios
        .get(`/api/products/get/one/${product_id}`)
        .then((response) => {
          setEditingProduct(response.data); // Set the editing product
          setName(response.data.name);
          setProductId(response.data.productId);
          setDescription(response.data.description);
          setImage(response.data.image);
          setSelectedCatg(response.data.businessCategory);
          setSelectedSubCatg(response.data.businessSubCategory);
          // setImage(response.data.image)
        })
        .catch((error) => {
          console.error("Error fetching product:", error.message);
        });
    }

    // Fetch categories here
  }, [product_id]);

  const getAllProduct = () => {
    axios
      .get("/api/businesscategory/get/list")
      .then((catgList) => {
        console.log("catgList => ", catgList.data);
        setCatgArr(catgList.data);
      })
      .catch((err) => {
        console.error("Error fetching categories:", err.message);
      });
  };


  useEffect(() => {
    getAllProduct();
   
  }, []);

  const categoryChange = (event) => {
    const categoryId = event.target.value;
    // const selectedCategory = catgArr.find((catg) => catg._id === categoryId);
    // setSelectedCatg(selectedCategory);
    setSelectedCatg(event.target.value);
    axios
      .get("/api/business-subcategory/get/list/" + categoryId)
      .then((subcatgList) => {
        console.log("subcatgList => ", subcatgList.data.subcategoryData);
        setSubCatgArr(subcatgList.data.subcategoryData);
      })
      .catch((err) => {
        console.error("Error fetching categories:", err.message);
      });
  };

  const subCategoryChange = (event) => {
    console.log("event.target.value => ", event.target.value);
    setSelectedSubCatg(event.target.value);
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
    console.log(e.target.files[0])
  };

  const resetForm = () => {
    console.log("reset");
    setName("");
    setProductId("");
    setImage("");
    setDescription("");
    setSelectedCatg(null); // Reset to null
    setSelectedSubCatg(null);
  };
  
  const handleDelete = (product_id) => {
    // Perform the delete action using axios
    axios.delete(`/api/products/delete/${product_id}`)
      .then((response) => {
        console.log("Product deleted:", response);
        // Refresh the product list after successful deletion
        getData(); // Corrected line
      })
      .catch((error) => {
        console.error("Error deleting product:", error.message);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setNameError("");
    setProductIdError("");
    setDescriptionError("");
    setImageError("");
    setCategoryError("");
    setSubCategoryError("");

        // Validation checks
        let isValid = true;

        if (!name.trim()) {
          setNameError("Name is required");
          isValid = false;
        } 
    
        if (!productId.trim()) {
          setProductIdError("Product ID is required");
          isValid = false;
        }
    
        if (!description.trim()) {
          setDescriptionError("Description is required");
          isValid = false;
        }
    
        if (!image) {
          setImageError("Image is required");
          isValid = false;
        }
    
        if (!selectedCatg) {
          setCategoryError("Category is required");
          isValid = false;
        }
    
        if (!selectedSubCatg) {
          setSubCategoryError("Subcategory is required");
          isValid = false;
        }
    
        if (!isValid) {
          return; // Do not proceed if there are validation errors
        }
  
    const formData = new FormData();
    formData.append('name', name);
    formData.append('productId', productId);
    formData.append('description', description);
    formData.append('businessCategory', selectedCatg);
    formData.append('businessSubCategory', selectedSubCatg);
    formData.append('image', image);
  
    try {
      let response;
      if (editingProduct) {
        response = await axios.patch("/api/products/patch/" + product_id, formData);
      } else {
        response = await axios.post("/api/products/post", formData);
      }
  
      const updatedProduct = response.data; // Store the updated product data
      console.log("Product updated/created:", updatedProduct);
  
      // Update the state with the new data
      setEditingProduct(updatedProduct);
      
      // Perform any other necessary operations after updating the state
      resetForm();
      setShowPopup(true);
      setPopupMessage("Product operation successful!");
      await getData();
      window.location.href = "/product-upload";
    } catch (error) {
      console.error("Error performing product operation:", error.message);
    }
  };
  
  
 

  const getData = () => {
    const formValue = {
      startRange: 0,
      limitRange: 10,
    };

    const listPromise = axios.post(apiLink + "/get/list", formValue);
    const countPromise = axios.get(apiLink + "/get/count", formValue);

    return Promise.all([listPromise, countPromise])
      .then(([listResponse, countResponse]) => {
        const tableData = listResponse.data.map((a, i) => ({
          _id: a._id,
          name: a.name,
          productId: a.productId,
          description: a.description,
          image: a.image,
          businessCategory: a.businessCategory,
          businessSubCategory: a.businessSubCategory,
        }));

        setTableData(tableData);
        setDataCount(countResponse.data.count);
      })
      .catch((error) => {
        console.error("Error getting data:", error.message);
      });
  };

  // debugger
  return (
    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 NOPadding">
      <section className="content">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 pageContent">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12 NOPadding topMargin box-header with-border">
                <h4 className="col-lg-12 col-md-12 col-xs-12 col-sm-12 weighttitle NOPadding">
                  Product Upload
                </h4>
              </div>
            </div>
            <div>
              <form
                style={{
                  marginLeft: "1%",
                }}
                required
                onSubmit={handleSubmit}
              >
                <div>
                  <div className="form-group">
                    <div className="form-group" style={{ marginTop: "80px" }}>
                      <label>Name </label>
                      <input
                        type="text"
                        className="form-control"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        style={{ width: "300px", height: "35px" }}
                        required
                      />
                      {nameError && <span className="error">{nameError}</span>}
                    </div>
                    <div className="form-group">
                      <label>Product ID</label>
                      <input
                        type="text"
                        className="form-control"
                        value={productId}
                        onChange={(e) => setProductId(e.target.value)}
                        style={{ width: "300px", height: "35px" }}
                        required
                      />
                      {productIdError && <span className="error">{productIdError}</span>}
                    </div>
                  </div>
                </div>
  
                <div
                  className="form-group"
                  style={{ display: "flex", gap: "30px", marginTop: "20px" }}
                >
                  <div className="form-group">
                    <label>Description</label>
                    <textarea
                      className="form-control"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      style={{ width: "300px" }}
                      required
                    />
                    {descriptionError && <span className="error">{descriptionError}</span>}
                  </div>
                  <div className="form-group">
                    <label>Image Upload</label>
                    <input
                      type="file"
                      className="form-control"
                      name="image"
                      // value={image}
                      onChange={handleImageChange}
                      style={{ width: "300px", height: "35px" }}
                      // required
                    />
                     {imageError && <span className="error">{imageError}</span>}
                  </div>
                </div>
                <div
                  className="form-group"
                  style={{ display: "flex", gap: "30px" }}
                >
                  <div className="form-group">
                    <label>Select Category</label>
                    <br />
                    <select
                      id="category"
                      className="addonDiv col-lg-12 col-md-12 col-sm-12 col-xs-12 form-control "
                      defaultValue={"-"}
                      value={selectedCatg}
                      onChange={categoryChange}
                      style={{ width: "300px", height: "35px" }}
                      required
                    >
                      <option value="-">Select Category</option>
                      {catgArr.length > 0
                        ? catgArr.map((catg, index) => {
                            return (
                              <option key={index} value={catg._id}>
                                {catg.businessCategory}
                              </option>
                            );
                          })
                        : null}
                    </select>
                    {categoryError && <span className="error">{categoryError}</span>}
                  </div>
                  <div className="form-group">
                    <label>Select Subcategory</label>
                    <br />
                    <select
                      id="subcategory"
                      className="addonDiv col-lg-12 col-md-12 col-sm-12 col-xs-12 form-control "
                      defaultValue={"-"}
                      value={selectedSubCatg}
                      onChange={subCategoryChange}
                      style={{ width: "300px", height: "35px" }}
                      required
                    >
                      <option value="-">Select Subcategory</option>
                      {subcatgArr.length > 0
                        ? subcatgArr.map((subcatg, index) => {
                            return (
                              <option key={index} value={subcatg._id}>
                                {subcatg.businessSubCategory}
                              </option>
                            );
                          })
                        : null}
                    </select>
                    {subCategoryError && <span className="error">{subCategoryError}</span>}
                  </div>
                </div>
  
                <button
                  type="submit"
                  className="btn btn-primary"
                  style={{ width: "180px", height: "30px" }}
                >
                  {editingProduct ? "Update" : "Submit"}
                </button>
              </form>
              {showPopup && (
                <div className="alert">
                  <p>{popupMessage}</p>
                </div>
              )}
              <div>
                <IAssureTable1
                  tableHeading={tableHeading}
                  twoLevelHeader={twoLevelHeader}
                  dataCount={dataCount}
                  tableData={tableData}
                  getData={getData.bind(this)}
                  tableObjects={tableObjects}
                  editId={editId}
                  deleteMethod={deleteMethod}
  
                  // ...other props
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};









